define("discourse/plugins/discourse-zoom/discourse/components/webinar", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers", "discourse/lib/show-modal", "@ember/object/computed", "discourse/lib/ajax", "@ember/runloop"], function (_exports, _component, _decorators, _webinarHelpers, _showModal, _computed, _ajax, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PENDING = "pending",
    ENDED = "ended",
    STARTED = "started";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("webinar.{status,ends_at}"), _dec2 = (0, _decorators.default)("webinar.status"), _dec3 = (0, _decorators.default)("webinar", "webinar.starts_at", "webinar.duration", "webinar.status"), _dec4 = (0, _decorators.default)("webinar"), _dec5 = (0, _decorators.default)("webinar.{starts_at,ends_at}"), (_obj = {
    loading: false,
    topic: null,
    webinar: null,
    webinarId: null,
    showTimer: false,
    canEdit: (0, _computed.alias)("topic.details.can_edit"),
    showingRecording: false,
    hostDisplayName: (0, _computed.or)("webinar.host.name", "webinar.host.username"),
    init() {
      this._super(...arguments);
      this.fetchDetails();
    },
    webinarEnded(webinar) {
      if (webinar.status === ENDED || moment(webinar.ends_at).isBefore(moment())) {
        return true;
      }
      return false;
    },
    webinarStarted(status) {
      return status === STARTED;
    },
    fetchDetails() {
      if (!this.webinarId) {
        return;
      }
      this.set("loading", true);
      this.store.find("webinar", this.webinarId).then(results => {
        this.setProperties({
          loading: false,
          webinar: results
        });
        this.messageBus.subscribe(this.messageBusEndpoint, data => {
          this.webinar.set("status", data.status);
        });
        this.appEvents.trigger("discourse-zoom:webinar-loaded");
      }).catch(() => {
        this.set("loading", false);
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.webinar) {
        this.messageBus.unsubscribe(this.messageBusEndpoint);
      }
      clearInterval(this.interval);
    },
    setupTimer(webinar, starts_at, duration, status) {
      if (status !== PENDING) {
        return false;
      }
      const startsAtMoment = moment(starts_at);
      this.interval = setInterval(interval => this.updateTimer(startsAtMoment, interval), 1000);
      this.updateTimer(startsAtMoment);
      return true;
    },
    updateTimer(starts_at, interval) {
      const duration = moment.duration(starts_at.diff(moment()));
      this.set("cSecs", duration.seconds());
      this.set("cMins", duration.minutes());
      this.set("cHours", duration.hours());
      this.set("cDays", parseInt(duration.asDays(), 10));
      if (starts_at.isBefore(moment())) {
        this.set("showTimer", false);
        if (interval) {
          clearInterval(interval);
        }
      } else {
        this.set("showTimer", true);
      }
    },
    messageBusEndpoint(webinar) {
      return `/zoom/webinars/${webinar.id}`;
    },
    displayAttendees() {
      return this.siteSettings.zoom_display_attendees;
    },
    schedule(webinar) {
      if (webinar.ends_at === null) {
        return moment(webinar.starts_at).format("Do MMMM, Y");
      }
      return (0, _webinarHelpers.formattedSchedule)(webinar.starts_at, webinar.ends_at);
    },
    actions: {
      editPanelists() {
        (0, _showModal.default)("edit-webinar", {
          model: this.webinar,
          title: "zoom.edit_webinar"
        });
      },
      showRecording() {
        this.set("showingRecording", true);
        (0, _runloop.next)(() => {
          const $videoEl = $(".video-recording");
          window.scrollTo({
            top: $videoEl.offset().top - 60,
            behavior: "smooth"
          });
          (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}/watch.json`, {
            type: "PUT"
          });
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "webinarEnded", [_dec], Object.getOwnPropertyDescriptor(_obj, "webinarEnded"), _obj), _applyDecoratedDescriptor(_obj, "webinarStarted", [_dec2], Object.getOwnPropertyDescriptor(_obj, "webinarStarted"), _obj), _applyDecoratedDescriptor(_obj, "setupTimer", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setupTimer"), _obj), _applyDecoratedDescriptor(_obj, "messageBusEndpoint", [_dec4], Object.getOwnPropertyDescriptor(_obj, "messageBusEndpoint"), _obj), _applyDecoratedDescriptor(_obj, "displayAttendees", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "displayAttendees"), _obj), _applyDecoratedDescriptor(_obj, "schedule", [_dec5], Object.getOwnPropertyDescriptor(_obj, "schedule"), _obj)), _obj)));
});