define("discourse/plugins/discourse-zoom/discourse/controllers/edit-webinar", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object/computed", "discourse-common/utils/decorators", "discourse-common/lib/helpers"], function (_exports, _controller, _modalFunctionality, _ajax, _ajaxError, _computed, _decorators, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("model.video_url", "newVideoUrl", "loading"), _dec2 = (0, _decorators.default)("model.panelists", "model.host"), _dec3 = (0, _decorators.default)("loading", "newPanelist"), _dec4 = (0, _decorators.default)("loading", "title", "pastStartDate"), (_obj = {
    model: null,
    newPanelist: null,
    loading: false,
    noNewPanelist: (0, _computed.not)("newPanelist"),
    newVideoUrl: null,
    canSaveVideoUrl(saved, newValue, loading) {
      if (saved === newValue || loading) {
        return true;
      }
      saved = saved === null ? "" : saved;
      newValue = newValue === null ? "" : newValue;
      return saved === newValue;
    },
    excludedUsernames(panelists, host) {
      return panelists.concat((0, _helpers.makeArray)(host)).map(p => p.username);
    },
    addingDisabled(loading, panelist) {
      return loading || !panelist;
    },
    updateDetailsDisabled(loading, title, pastStartDate) {
      return loading || this.model.title === title && this.model.starts_at === pastStartDate;
    },
    onShow() {
      this.setProperties({
        newVideoUrl: this.model.video_url,
        hostUsername: this.model.host ? this.model.host.username : null,
        title: this.model.title,
        pastStartDate: this.model.starts_at
      });
      if (this.model.zoom_id === "nonzoom") {
        this.set("nonZoomWebinar", true);
      }
    },
    actions: {
      saveVideoUrl() {
        this.set("loading", true);
        (0, _ajax.ajax)(`/zoom/webinars/${this.model.id}/video_url.json`, {
          data: {
            video_url: this.newVideoUrl
          },
          type: "PUT"
        }).then(results => {
          this.model.set("video_url", results.video_url);
        }).finally(() => {
          this.set("loading", false);
        });
      },
      resetVideoUrl() {
        this.set("newVideoUrl", this.model.video_url);
      },
      removePanelist(panelist) {
        this.set("loading", true);
        (0, _ajax.ajax)(`/zoom/webinars/${this.model.id}/panelists/${panelist.username}.json`, {
          type: "DELETE"
        }).then(() => {
          this.store.find("webinar", this.model.id).then(webinar => {
            this.set("model", webinar);
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      },
      addPanelist() {
        this.set("loading", true);
        (0, _ajax.ajax)(`/zoom/webinars/${this.model.id}/panelists/${this.newPanelist}.json`, {
          type: "PUT"
        }).then(() => {
          this.set("newPanelist", null);
          this.store.find("webinar", this.model.id).then(webinar => {
            this.set("model", webinar);
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      },
      onChangeDate(date) {
        if (!date) {
          return;
        }
        this.set("pastStartDate", date);
      },
      onChangeHost(selected) {
        this.set("hostUsername", selected.firstObject);
        this.set("loading", true);
        let hostUsername = this.hostUsername,
          postType = "PUT";
        if (!this.hostUsername) {
          hostUsername = this.model.host.username;
          postType = "DELETE";
        }
        (0, _ajax.ajax)(`/zoom/webinars/${this.model.id}/nonzoom_host/${hostUsername}.json`, {
          type: postType
        }).then(() => {
          this.store.find("webinar", this.model.id).then(webinar => {
            this.set("model", webinar);
          });
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      },
      updateDetails() {
        this.set("loading", true);
        (0, _ajax.ajax)(`/zoom/webinars/${this.model.id}/nonzoom_details.json`, {
          type: "PUT",
          data: {
            title: this.title,
            past_start_date: moment(this.pastStartDate).format()
          }
        }).then(() => {
          this.set("model.title", this.title);
          this.set("model.starts_at", this.pastStartDate);
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      },
      updateNewPanelist(selected) {
        this.set("newPanelist", selected.firstObject);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canSaveVideoUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "canSaveVideoUrl"), _obj), _applyDecoratedDescriptor(_obj, "excludedUsernames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "excludedUsernames"), _obj), _applyDecoratedDescriptor(_obj, "addingDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "addingDisabled"), _obj), _applyDecoratedDescriptor(_obj, "updateDetailsDisabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "updateDetailsDisabled"), _obj)), _obj)));
});