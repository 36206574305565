define("discourse/plugins/discourse-zoom/discourse/controllers/webinar-picker", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _modalFunctionality, _ajax, _ajaxError, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const NONZOOM = "nonzoom";
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("webinar"), _dec2 = (0, _decorators.default)("pastWebinarTitle", "pastStartDate"), (_obj = {
    topicController: (0, _controller.inject)("topic"),
    webinarId: null,
    webinarIdInput: null,
    webinar: null,
    model: null,
    loading: false,
    selected: false,
    addingPastWebinar: false,
    pastStartDate: moment(new Date(), "YYYY-MM-DD").toDate(),
    pastWebinarTitle: "",
    allWebinars: null,
    error: false,
    NO_REGISTRATION_REQUIRED: 2,
    onShow() {
      if (!this.webinar) {
        if (this.model && this.model.get("webinar.zoom_id")) {
          this.set("webinarId", this.model.get("webinar.zoom_id"));
          this.set("webinarIdInput", this.model.get("webinar.zoom_id"));
        }
        if (!this.selected) {
          (0, _ajax.ajax)("/zoom/webinars").then(results => {
            if (results && results.webinars) {
              this.set("allWebinars", results.webinars);
            }
          });
        }
      }
    },
    onClose() {
      this.setProperties({
        allWebinars: null,
        selected: false,
        webinarIdInput: null,
        webinar: null,
        error: false,
        addingPastWebinar: false
      });
    },
    scrubWebinarId(webinarId) {
      return webinarId.replace(/-|\s/g, "");
    },
    addWebinarToTopic() {
      const webinarId = this.webinar ? this.webinar.id : NONZOOM;
      let data = {};
      if (this.pastWebinarTitle && this.pastStartDate) {
        data = {
          zoom_title: this.pastWebinarTitle,
          zoom_start_date: moment(this.pastStartDate).format()
        };
      }
      (0, _ajax.ajax)(`/zoom/t/${this.model.id}/webinars/${webinarId}`, {
        type: "PUT",
        data
      }).then(results => {
        this.store.find("webinar", results.id).then(webinar => {
          this.model.set("webinar", webinar);
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
        this.topicController.set("editingTopic", false);
        this.model.postStream.posts[0].rebake();
        document.querySelector("body").classList.add("has-webinar");
      });
    },
    addWebinarToComposer() {
      this.model.set("zoomId", this.webinar.id);
      this.model.set("zoomWebinarTitle", this.webinar.title);
    },
    fetchWebinarDetails(id) {
      id = this.scrubWebinarId(id.toString());
      this.set("loading", true);
      this.setProperties({
        loading: true,
        error: false
      });
      (0, _ajax.ajax)(`/zoom/webinars/${id}/preview`).then(json => {
        this.setProperties({
          webinar: json,
          selected: true
        });
      }).catch(() => {
        this.setProperties({
          webinar: null,
          selected: false,
          error: true
        });
      }).finally(() => {
        this.set("loading", false);
      });
    },
    webinarError(webinar) {
      if (webinar.approval_type !== this.NO_REGISTRATION_REQUIRED) {
        return _I18n.default.t("zoom.no_registration_required");
      }
      if (webinar.existing_topic) {
        return _I18n.default.t("zoom.webinar_existing_topic", {
          topic_id: webinar.existing_topic.topic_id
        });
      }
      return false;
    },
    pastWebinarDisabled(pastWebinarTitle, pastStartDate) {
      return !pastWebinarTitle || !pastStartDate;
    },
    actions: {
      selectWebinar(id) {
        this.fetchWebinarDetails(id);
      },
      clear() {
        this.set("selected", false);
      },
      insert() {
        if (this.model.addToTopic) {
          this.addWebinarToTopic();
        } else {
          this.addWebinarToComposer();
        }
        this.send("closeModal");
      },
      addPastWebinar() {
        this.model.set("zoomId", NONZOOM);
        this.model.set("zoomWebinarTitle", this.pastWebinarTitle);
        this.model.set("zoomWebinarStartDate", moment(this.pastStartDate).format());
        if (this.model.addToTopic) {
          this.addWebinarToTopic();
        }
        this.send("closeModal");
      },
      showPastWebinarForm() {
        this.set("addingPastWebinar", true);
        this.set("selected", false);
      },
      onChangeDate(date) {
        if (!date) {
          return;
        }
        this.set("pastStartDate", date);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "webinarError", [_dec], Object.getOwnPropertyDescriptor(_obj, "webinarError"), _obj), _applyDecoratedDescriptor(_obj, "pastWebinarDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "pastWebinarDisabled"), _obj)), _obj)));
});