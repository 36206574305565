define("discourse/plugins/discourse-zoom/discourse/initializers/admin-menu-webinar-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-owner", "I18n"], function (_exports, _pluginApi, _showModal, _ajax, _ajaxError, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-zoom";
  function initialize(api) {
    api.decorateWidget("topic-admin-menu:adminMenuButtons", helper => {
      const topic = helper.attrs.topic;
      const {
        canManageTopic
      } = helper.widget.currentUser || {};
      if (!topic.isPrivateMessage && canManageTopic) {
        return {
          buttonClass: "btn-default",
          action: topic.webinar ? "removeWebinar" : "addWebinar",
          icon: "shield-alt",
          fullLabel: topic.webinar ? "zoom.remove_webinar" : "zoom.add_webinar"
        };
      }
    });
    api.modifyClass("component:topic-admin-menu-button", {
      pluginId: PLUGIN_ID,
      removeWebinar() {
        removeWebinar(this.topic);
      },
      addWebinar() {
        showWebinarModal(this.topic);
      }
    });
    api.modifyClass("component:topic-timeline", {
      pluginId: PLUGIN_ID,
      removeWebinar() {
        removeWebinar(this.topic);
      },
      addWebinar() {
        showWebinarModal(this.topic);
      }
    });
  }
  var _default = _exports.default = {
    name: "admin-menu-webinar-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
  function showWebinarModal(model) {
    model.set("addToTopic", true);
    (0, _showModal.default)("webinar-picker", {
      model,
      title: "zoom.webinar_picker.title"
    });
  }
  function removeWebinar(topic) {
    const dialog = (0, _getOwner.getOwner)(this).lookup("service:dialog");
    dialog.confirm({
      message: _I18n.default.t("zoom.confirm_remove"),
      didConfirm: () => {
        (0, _ajax.ajax)(`/zoom/webinars/${topic.webinar.id}`, {
          type: "DELETE"
        }).then(() => {
          topic.set("webinar", null);
          const topicController = (0, _getOwner.getOwner)(this).lookup("controller:topic");
          topicController.set("editingTopic", false);
          document.querySelector("body").classList.remove("has-webinar");
          topic.postStream.posts[0].rebake();
        }).catch(_ajaxError.popupAjaxError);
      }
    });
  }
});