define("discourse/plugins/discourse-zoom/discourse/initializers/zoom-composer", ["exports", "discourse/models/composer", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _composer, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "zoom-composer",
    initialize() {
      _composer.default.serializeOnCreate("zoom_id", "zoomId");
      _composer.default.serializeOnCreate("zoom_webinar_title", "zoomWebinarTitle");
      _composer.default.serializeOnCreate("zoom_webinar_start_date", "zoomWebinarStartDate");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        var _dec, _obj;
        api.decorateWidget("post:before", dec => {
          if (dec.canConnectComponent && dec.attrs.firstPost) {
            if (!dec.attrs.cloaked) {
              return dec.connect({
                component: "post-top-webinar",
                context: "model"
              });
            }
          }
        });
        api.modifyClass("controller:topic", (_dec = (0, _decorators.observes)("model.postStream.loaded"), (_obj = {
          pluginId: "discourse-zoom",
          _addWebinarClass() {
            const webinar = this.get("model.webinar");
            if (webinar) {
              document.querySelector("body").classList.add("has-webinar");
            } else {
              document.querySelector("body").classList.remove("has-webinar");
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "_addWebinarClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "_addWebinarClass"), _obj)), _obj)));
      });
    }
  };
});