define("discourse/plugins/discourse-zoom/discourse/initializers/composer-toolbar-webinar-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal"], function (_exports, _pluginApi, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWebinarButton(api) {
    const composerService = api.container.lookup("service:composer");
    api.addComposerToolbarPopupMenuOption({
      condition: composer => {
        return composer.model && composer.model.creatingTopic;
      },
      icon: "video",
      label: "zoom.webinar_picker.button",
      action: () => {
        (0, _showModal.default)("webinar-picker", {
          model: composerService.model,
          title: "zoom.webinar_picker.title"
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "composer-toolbar-webinar-button",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      if (siteSettings.zoom_enabled && currentUser) {
        (0, _pluginApi.withPluginApi)("1.13.0", initializeWebinarButton);
      }
    }
  };
});